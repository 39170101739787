import { useEffect, useState } from "react";
import { Button, Card, Paper, SelectChangeEvent, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TripSummaryInterface } from "./TripSummaryInterface";
import TripFullScreenDialog from "../TripDialog/TripFullScreenDialog";
import TripFilterSelect from "./TripFilterSelect";

const AllTripsTable = () => {
  const baseURL: string = process.env.REACT_APP_BASE_API_URL
    ? process.env.REACT_APP_BASE_API_URL
    : "";
  const allTripTotalsURL: string = process.env.REACT_APP_ALL_TRIPS_API_URL
    ? process.env.REACT_APP_ALL_TRIPS_API_URL
    : "";
  const tripsURL = `${baseURL}${allTripTotalsURL}`;
  const [getResult, setGetResult] = useState<TripSummaryInterface[]>([]);
  const [selectedTrip, setSelectedTrip] = useState<number>(0);
  const [campgroundList, setCampgroundList] = useState<string[]>();
  const [stateList, setStateList] = useState<string[]>([]);
  const [filterState, setFilterState] = useState("");
  const [filterCampground, setFilterCampground] = useState("");
  const [unfilteredData, setUnfilteredData] = useState<TripSummaryInterface[]>(
    []
  );

  useEffect(() => {
    getAllData();
  }, [tripsURL]);

  useEffect(() => {
    setGetResult(unfilteredData);
  }, [unfilteredData]);

  let currentTripNumber: number = 0;

  const setTripLists = (tripList: TripSummaryInterface[]): void => {
    let states: string[] = tripList.map((x) => x.state);
    let campgrounds: string[] = tripList.map((x) => x.campground);

    states = states.sort().filter(function (item, pos, ary) {
      return !pos || item !== ary[pos - 1];
    });
    setStateList(states);
    campgrounds = campgrounds.sort().filter(function (item, pos, ary) {
      return !pos || item !== ary[pos - 1];
    });
    setCampgroundList(campgrounds);
  };

  const getAllData = async () => {
    currentTripNumber = 0;
    try {
      const res = await fetch(`${tripsURL}`);
      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }

      const data: TripSummaryInterface[] = await res.json();
      setUnfilteredData(data);
      setTripLists(data);
    } catch (err: any) {
      setGetResult(err.message);
    }
  };

  const getAdjustedTripNumber = (index: number): string => {
    if (index === 0 || getResult[index].id !== getResult[index - 1].id) {
      return String(++currentTripNumber);
    } else {
      return "";
    }
  };

  const closeTrip = () => {
    setSelectedTrip(0);
  };

  const openTrip = (tripId: number) => {
    setSelectedTrip(tripId);
  };

  const filterCampgrounds = (event: SelectChangeEvent<any>) => {
    setFilterCampground(event.target.value);
  };

  const filterStates = (event: SelectChangeEvent<any>) => {
    setFilterState(event.target.value);
  };

  const filterTrips = () => {
    let filteredData: TripSummaryInterface[] = unfilteredData;

    if (filterState !== "") {
      filteredData = filteredData.filter(
        (value) => value.state === filterState
      );
    }

    if (filterCampground !== "") {
      filteredData = filteredData.filter(
        (value) => value.campground === filterCampground
      );
    }

    setGetResult(filteredData);
  };

  return (
    <Card
      sx={{
        marginTop: "10px",
        marginBottm: "10px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Stack
        marginTop={"10px"}
        justifyContent={"center"}
        direction="row"
        spacing={2}
      >
        <TripFilterSelect
          names={campgroundList || []}
          valueChangeFunction={filterCampgrounds}
          fieldName={"Campgrounds"}
        />
        <TripFilterSelect
          names={stateList || []}
          valueChangeFunction={filterStates}
          fieldName={"States"}
        />
        <Button variant="outlined" onClick={filterTrips}>
          Filter
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Trip</TableCell>
              <TableCell align="center">Stop</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Campground</TableCell>
              <TableCell align="center">State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getResult.map((row, index) => (
              <TableRow
                hover={true}
                onClick={() => openTrip(row.id)}
                key={index}
              >
                <TableCell align="center" scope="row">
                  {getAdjustedTripNumber(index)}
                </TableCell>
                <TableCell align="center">{row.stopNumber}</TableCell>
                <TableCell align="center">
                  {new Date(`${row.stopDate}T11:00:00`).toLocaleDateString()}
                </TableCell>
                <TableCell align="left">{row.campground}</TableCell>
                <TableCell align="right">{row.state}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TripFullScreenDialog
        data={getResult}
        tripId={selectedTrip}
        onClose={closeTrip}
      />
    </Card>
  );
};

export default AllTripsTable;
