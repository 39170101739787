import { Box, Card, Link as LinkMaterial } from "@mui/material";
import { Link } from "react-router-dom";
import { PhotoDirectorySummaryInterface } from "./PhotoDirectorySummaryInterface";

interface OwnProps {
  data: PhotoDirectorySummaryInterface;
}

type Props = OwnProps;

const PhotoDirectoryContent = (props: Props) => {
  const baseURL: string = process.env.REACT_APP_ROOT_URL
    ? process.env.REACT_APP_ROOT_URL
    : "";

  return (
    <div>
      <Card raised sx={{ margin: "10px", padding: "10px", display: "flex" }}>
        {props.data.type === "file" && (
          <Box width={"fit-content"} height={"fit-content"}>
            <div>
              <LinkMaterial
                target="_blank"
                rel="noopener"
                href={`${baseURL}${props.data.path}/${props.data.name.replace(
                  "thumb",
                  ""
                )}`}
              >
                <img
                  src={`${baseURL}${props.data.path}/${props.data.name}`}
                  alt={props.data.name}
                  loading={"lazy"}
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                ></img>
              </LinkMaterial>
            </div>
          </Box>
        )}
        {props.data.type === "directory" && (
          <Link to={"/pictures"} state={props.data}>
            {props.data.name.split(" - ").at(-1)}
          </Link>
        )}
      </Card>
    </div>
  );
};

export default PhotoDirectoryContent;
