import { createContext, useContext } from "react";

import { User } from "../App";

export const AuthContext = createContext<User | undefined>(undefined);

//Call const user = useUserContext(); from child to get user...
export function useUserContext() {
  const user = useContext(AuthContext);

  if (user === undefined) {
    throw new Error("useUserContext must be used with a AuthContext");
  }

  return user;
}
