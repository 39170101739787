import {
  Box,
  Card,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { StopDetailsInterface } from "./StopDetailsInterface";

interface OwnProps {
  data: StopDetailsInterface;
}

type Props = OwnProps;

const StopDetails = (props: Props) => {
  return (
    <Card raised sx={{ margin: "10px", padding: "10px" }}>
      <Grid container spacing={2} marginBottom={"20px"}>
        <Grid item xs={3}>
          <Box>
            <Typography
              align={"left"}
              sx={{
                fontWeight: 700,
              }}
            >
              {`Stop #: ${props.data.stopNumber}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Link to={props.data.website} target="_blank" rel="noopener">
              <Typography
                align={"center"}
                sx={{
                  fontWeight: 700,
                }}
              >
                <Box>{props.data.campground}</Box>
                {`${props.data.city}, ${props.data.state}`}
              </Typography>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography
              align={"right"}
              sx={{
                fontWeight: 700,
              }}
            >
              {new Date(`${props.data.stopDate}T11:00:00`).toLocaleDateString()}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Stack direction={"column"}>
        <ul>
          {props.data.details
            .split(/[\r\n]+/)
            .map((row: string, index: number) => {
              return row.length > 0 && <li>{row}</li>;
            })}
          {props.data.notes
            .split(/[\r\n]+/)
            .map((row: string, index: number) => {
              return row.length > 0 && <li>{row}</li>;
            })}
        </ul>
      </Stack>
    </Card>
  );
};

export default StopDetails;
