import { useEffect, useState } from "react";
import AllTripsAllCampers from "./AllTripsAllCampers";
import { TripsForCamperSummaryInterface } from "./TripsForCamperSummaryInterface";
import { Box, Container, Stack } from "@mui/material";
import AllTripsSpecificCamper from "./AllTripsSpecificCamper";
import AllTripsTable from "./AllTripsTable";

const AllTripsSummary = () => {
  const baseURL: string = process.env.REACT_APP_BASE_API_URL
    ? process.env.REACT_APP_BASE_API_URL
    : "";
  const tripTotalsByTrailerURL: string = process.env
    .REACT_APP_TRIPS_TOTALS_BY_TRAILER_API_URL
    ? process.env.REACT_APP_TRIPS_TOTALS_BY_TRAILER_API_URL
    : "";
  const tripsURL = `${baseURL}${tripTotalsByTrailerURL}`;

  const [getResult, setGetResult] = useState<TripsForCamperSummaryInterface[]>(
    []
  );

  useEffect(() => {
    getAllData();
  }, [tripsURL]);

  const getAllData = async () => {
    try {
      const res = await fetch(`${tripsURL}`);
      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }

      const data: TripsForCamperSummaryInterface[] = await res.json();

      setGetResult(data);
    } catch (err: any) {
      setGetResult(err.message);
    }
  };

  const getTotals = (): [number, number] => {
    let totalTrips: number = 0;
    let totalMiles: number = 0;

    getResult.forEach((camperTotals) => {
      totalTrips += camperTotals.trips;
      totalMiles += camperTotals.miles;
    });

    return [totalTrips, totalMiles];
  };

  const [totalTrips, totalMiles] = getTotals();

  return (
    <Container>
      <AllTripsAllCampers trips={totalTrips} miles={totalMiles} />
      <Container sx={{ marginTop: "10px" }}>
        <Stack direction="row" justifyContent={"center"} flexWrap={"wrap"}>
          {getResult.map(({ trips, miles, camper }) => (
            <Box key={camper}>
              <AllTripsSpecificCamper
                trips={trips}
                miles={miles}
                camper={camper}
              />
            </Box>
          ))}
        </Stack>
      </Container>
      <AllTripsTable />
    </Container>
  );
};

export default AllTripsSummary;
