import React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Card, Container, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { TripSummaryInterface } from "../TripsSummary/TripSummaryInterface";
import { TripDetailsInterface } from "./TripDetailsInterface";
import { StopDetailsInterface } from "./StopDetailsInterface";
import StopDetails from "./StopDetails";

interface OwnProps {
  data: TripSummaryInterface[];
  tripId: number;
  onClose: Function;
}

type Props = OwnProps;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TripFullScreenDialog = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [tripDetails, setTripDetails] = useState<TripDetailsInterface>({
    description: "",
    pictures: "",
    picturesDirectory: "",
    picturesPath: "",
    miles: 0,
    camper: "",
    stopDetails: [],
  });

  useEffect(() => {
    if (props.tripId > 0) {
      handleClickOpen();
    }
  }, [props.tripId]);

  const extractStopDetails = (
    tripStops: TripSummaryInterface[]
  ): StopDetailsInterface[] => {
    let retVal: StopDetailsInterface[] = [];

    tripStops.forEach((element) => {
      retVal.push(
        (({
          notes,
          stopNumber,
          stopDate,
          campground,
          website,
          city,
          state,
          details,
        }) => ({
          notes,
          stopNumber,
          stopDate,
          campground,
          website,
          city,
          state,
          details,
        }))(element)
      );
    });

    return retVal;
  };

  const handleClickOpen = () => {
    const tripStops: TripSummaryInterface[] = props.data.filter(
      (stopDetails) => stopDetails.id === props.tripId
    );

    if (tripStops.length > 0) {
      const stopDetails: StopDetailsInterface[] = extractStopDetails(tripStops);

      setTripDetails({
        description: tripStops[0].description,
        pictures: tripStops[0].pictures,
        picturesDirectory: tripStops[0].pictures.split("/").at(-1),
        picturesPath: tripStops[0].pictures.replace(
          tripStops[0].pictures.split("/").at(-1) || "",
          ""
        ),
        miles: tripStops[0].miles,
        camper: tripStops[0].camper,
        stopDetails: stopDetails,
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ justifyContent: "right" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container>
          <Card raised sx={{ margin: "10px", padding: "10px" }}>
            <Card raised sx={{ margin: "10px", padding: "10px" }}>
              <Grid container spacing={2} marginBottom={"20px"}>
                <Grid item xs={6}>
                  <Box>
                    <Typography
                      align={"left"}
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {`Camper: ${tripDetails.camper}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box justifyContent={"right"}>
                    <Typography
                      align={"right"}
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {`Miles: ${tripDetails.miles}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {tripDetails.description
                .split(/[\r\n]+/)
                .map((row: string, index: number) => {
                  return (
                    <Box key={index} marginBottom={"10px"}>
                      {row}
                    </Box>
                  );
                })}
              {tripDetails.picturesPath && tripDetails.picturesDirectory && (
                <Link
                  to={"/pictures"}
                  state={{
                    path: `./${tripDetails.picturesPath}/`,
                    name: `${tripDetails.picturesDirectory}`,
                  }}
                >
                  Pictures
                </Link>
              )}
            </Card>
            <Stack direction={"column"}>
              {tripDetails.stopDetails.map(
                (row: StopDetailsInterface, index: number) => (
                  <StopDetails key={index} data={row}></StopDetails>
                )
              )}
            </Stack>
          </Card>
        </Container>
        <Container>
          <Box textAlign={"center"}>
            <Button onClick={handleClose} variant="contained">
              Close
            </Button>
          </Box>
        </Container>
      </Dialog>
    </React.Fragment>
  );
};

export default TripFullScreenDialog;
