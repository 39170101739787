import { Card } from "@mui/material";

interface OwnProps {
  value: number;
}

type Props = OwnProps;

const CountdownNumber = (props: Props) => {
  return (
    <Card
      raised
      sx={{ padding: "5px", paddingLeft: "7px", paddingRight: "7px" }}
    >
      {props.value.toString().padStart(2, "0")}
    </Card>
  );
};

export default CountdownNumber;
