import { Box, Card, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CountdownNumber from "./CountdownNumber";

const CountdownTimer = () => {
  const [years, setYears] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const targetDate: Date = new Date("Apr 19, 2025");
  let timer: NodeJS.Timer;

  useEffect(() => {
    calculateTime();
    startCountdown();
  }, []);

  // Function used to adjust for leap years.
  const includeYear = (dateToCheck: Date, type: string): boolean => {
    let retVal: boolean = true;
    const currentMonth: number = dateToCheck.getMonth();

    if (
      (currentMonth >= 2 && type === "start") ||
      (currentMonth < 2 && type === "end")
    ) {
      retVal = false;
    }

    return retVal;
  };

  //Function to see how many leap years there are between the dates.
  const checkForLeapYears = (startYear: number, endYear: number): number => {
    let retVal = 0;

    for (var i = startYear; i <= endYear; i++) {
      if (new Date(i, 1, 29).getMonth() === 1) {
        retVal++;
      }
    }

    return retVal;
  };

  const calculateTime = (): void => {
    // Find the number of "seconds" between now and target
    const currentDate: Date = new Date();
    let secondsLeft: number =
      (targetDate.getTime() - currentDate.getTime()) / 1000;

    // Determine how many leap years between these 2 dates.
    let currentYear: number = currentDate.getFullYear();
    let targetYear: number = targetDate.getFullYear();

    if (!includeYear(currentDate, "start")) {
      currentYear++;
    }

    if (!includeYear(targetDate, "end")) {
      targetYear--;
    }

    let leapYears: number = checkForLeapYears(currentYear, targetYear);
    const tmpYear: number = Math.floor((secondsLeft / 86400 - leapYears) / 365);
    const tmpDays: number = Math.floor(
      secondsLeft / 86400 - tmpYear * 365 - leapYears
    );
    secondsLeft = secondsLeft % 86400;
    const tmpHours: number = Math.floor(secondsLeft / 3600);
    secondsLeft = secondsLeft % 3600;
    const tmpMinutes: number = Math.floor(secondsLeft / 60);

    setYears(tmpYear);
    setDays(tmpDays);
    setHours(tmpHours);
    setMinutes(tmpMinutes);
  };

  // Update the tag with id "countdown" every 1 second
  const startCountdown = (): void => {
    clearInterval(timer);
    calculateTime();
    timer = setInterval(() => {
      calculateTime();
    }, 6000);
  };

  return (
    <Box marginBottom={"20px"}>
      <Card>
        <Stack
          direction="row"
          spacing={2}
          margin={"10px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Stack spacing={1} alignItems={"center"} justifyContent={"center"}>
            <CountdownNumber value={years} />
            <span>Years</span>
          </Stack>
          <Stack spacing={1} alignItems={"center"} justifyContent={"center"}>
            <CountdownNumber value={days} />
            <span>Days</span>
          </Stack>
          <Stack spacing={1} alignItems={"center"} justifyContent={"center"}>
            <CountdownNumber value={hours} />
            <span>Hours</span>
          </Stack>
          <Stack spacing={1} alignItems={"center"} justifyContent={"center"}>
            <CountdownNumber value={minutes} />
            <span>Minutes</span>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default CountdownTimer;
