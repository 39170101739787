import { Container, Stack } from "@mui/material";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";
import { Outlet } from "react-router-dom";

const MainPage = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <Stack spacing={4}>
        <ResponsiveAppBar />
        <Container maxWidth={false}>
          <Outlet />
        </Container>
      </Stack>
    </Container>
  );
};

export default MainPage;
