import { Container, Grid } from "@mui/material";
import MainPageOption from "../components/MainPageOption";
import CamperImage from "../images/camper.jpg";
import FamilyImage from "../images/photos.jpg";
import CountdownTimer from "../components/CountdownTimer/CountdownTimer";

const HomePage = () => {
  return (
    <Container>
      <Grid container justifyContent="space-evenly" alignItems="center">
        <Grid item xs={12} sm={5} md={6} key={1}>
          <MainPageOption
            title={"Pictures"}
            url={"/pictures"}
            image={FamilyImage}
            description={
              "Family pictures going back to 1939.  The goal is to continue to add pictures as time allow."
            }
          />
        </Grid>
        <Grid item xs={12} sm={5} md={6} key={2}>
          <MainPageOption
            title={"Camping Trips"}
            url={"/camping2"}
            image={CamperImage}
            description={
              "Information about camping trips that we have taken since buying our first camper in 2015."
            }
          />
        </Grid>
        <Grid item>
          <CountdownTimer />
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
