import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { PhotoDirectorySummaryInterface } from "../PhotoDirectorySummary/PhotoDirectorySummaryInterface";

interface OwnProps {
  title: string;
  url: string;
}

type Props = OwnProps;

const MainMenuOption = (props: Props) => {
  const rootPhotoDirectory: PhotoDirectorySummaryInterface = {
    name: "",
    path: ".",
    type: "directory",
  };

  return (
    <Link
      to={props.url}
      state={rootPhotoDirectory}
      style={{ textDecoration: "none" }}
    >
      <Typography
        paddingLeft={"20px"}
        paddingRight={"20px"}
        borderRadius={"5px"}
        border={"solid"}
        borderColor={"lightgrey"}
        sx={{
          ml: 2,
          mr: 2,
          fontFamily: "roboto",
          fontWeight: 700,
          letterSpacing: ".3rem",
          color: "white",
          textDecoration: "none",
          borderWidth: "1px",
        }}
      >
        {props.title}
      </Typography>
    </Link>
  );
};

export default MainMenuOption;
