import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import MainMenuOption from "./MainMenuOption";
import { PhotoDirectorySummaryInterface } from "../PhotoDirectorySummary/PhotoDirectorySummaryInterface";
import { Button } from "@mui/material";
import { useState } from "react";
import Login from "../Login/Login";

const ResponsiveAppBar = () => {
  const [open, setOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const rootPhotoDirectory: PhotoDirectorySummaryInterface = {
    name: "",
    path: ".",
    type: "directory",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar disableGutters>
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/home"
            sx={{
              ml: 2,
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Lake Gypsy
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="main menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/home">Home</Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/pictures" state={rootPhotoDirectory}>
                  Pictures
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/camping2">Camping Trips</Link>
              </MenuItem>
              {/* <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/camping3">Camping Trips Admin</Link>
              </MenuItem> */}
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/camping-documents">Camping Documents</Link>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Lake Gypsy
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            <MainMenuOption title={"Home"} url={"/home"}></MainMenuOption>
            <MainMenuOption
              title={"Pictures"}
              url={"/pictures"}
            ></MainMenuOption>
            <MainMenuOption
              title={"Camping Trips"}
              url={"/camping2"}
            ></MainMenuOption>
            {/* <MainMenuOption
              title={"Camping Trips Admin"}
              url={"/camping3"}
            ></MainMenuOption> */}
            <MainMenuOption
              title={"Camping Documents"}
              url={"/camping-documents"}
            ></MainMenuOption>
            {/* <Button sx={{ color: "white" }} onClick={handleClickOpen}>
              Login
            </Button> */}
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Login open={open} onClose={handleClose}></Login> */}
    </>
  );
};
export default ResponsiveAppBar;
