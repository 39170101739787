import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { PhotoDirectorySummaryInterface } from "./PhotoDirectorySummaryInterface";
import PhotoDirectoryContent from "./PhotoDirectoryContent";
import { useLocation } from "react-router-dom";
import PhotoDirectoryBreadCrumb from "./PhotoDirectoryBreadCrumb";

const PhotoDirectorySummary = () => {
  const location = useLocation();
  const subDirectory: PhotoDirectorySummaryInterface = location.state;

  const baseURL: string = process.env.REACT_APP_BASE_API_URL
    ? process.env.REACT_APP_BASE_API_URL
    : "";
  const getPhotoDirectoriesURL: string = process.env
    .REACT_APP_PHOTO_DIRECTORY_CONTENTS_API_URL
    ? process.env.REACT_APP_PHOTO_DIRECTORY_CONTENTS_API_URL
    : "";
  const path: string = subDirectory ? subDirectory.path : "";
  const directoryName: string = subDirectory ? subDirectory.name : "";
  const picturesURL = `${baseURL}${getPhotoDirectoriesURL}?arg_directory=${path}${directoryName}`;
  const [getResult, setGetResult] = useState<PhotoDirectorySummaryInterface[]>(
    []
  );
  const [directoryURL, setDirectoryURL] = useState<string>(
    `${path}${directoryName}`
  );

  useEffect(() => {
    setDirectoryURL(`${path}${directoryName}`);
  }, [path, directoryName]);

  useEffect(() => {
    getAllData();
  }, [picturesURL, subDirectory]);

  const getAllData = async () => {
    try {
      const res = await fetch(`${picturesURL}`);
      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }

      const data: PhotoDirectorySummaryInterface[] = await res.json();

      setGetResult(data);
    } catch (err: any) {
      setGetResult(err.message);
    }
  };

  return (
    <>
      <PhotoDirectoryBreadCrumb url={directoryURL} />
      <Box textAlign={"center"}>
        <Typography
          sx={{
            fontSize: "2rem",
            fontFamily: "roboto",
            fontWeight: 700,
            letterSpacing: ".3rem",
          }}
        >
          {`${directoryName.split(" - ").at(-1)} Pictures`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        {getResult &&
          getResult
            .filter(
              (row: PhotoDirectorySummaryInterface) => row.type === "directory"
            )
            .map((row: PhotoDirectorySummaryInterface, index: number) => (
              <PhotoDirectoryContent
                key={index}
                data={row}
              ></PhotoDirectoryContent>
            ))}
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", alignContent: "center" }}>
        {getResult &&
          getResult
            .filter(
              (row: PhotoDirectorySummaryInterface) => row.type === "file"
            )
            .map((row: PhotoDirectorySummaryInterface, index: number) => (
              <PhotoDirectoryContent
                key={index}
                data={row}
              ></PhotoDirectoryContent>
            ))}
      </Box>
      <PhotoDirectoryBreadCrumb url={directoryURL} />
    </>
  );
};

export default PhotoDirectorySummary;
