import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import MainPage from "./pages/MainPage";
import HomePage from "./pages/HomePage";
import NoPage from "./pages/NoPage";
import CampingPage from "./pages/CampingPage";
import CampingPageAdmin from "./pages/CampingPageAdmin";
import PicturesPage from "./pages/PicturesPage";
import CampingDocumentsPage from "./pages/CampingDocumentsPage";
import NotAuthorizedPage from "./pages/NotAuthorizedPage";
import { AuthContext } from "./context/AuthContext";

export interface User {
  name: string;
  password: string;
  updateUserFunction: React.Dispatch<React.SetStateAction<User>>;
}

const App = () => {
  const [currentUser, setCurrentUser] = useState<User>({
    name: "Taylor",
    password: "Swift",
    updateUserFunction: () => {},
  });

  useEffect(() => {
    const newUser: User = {
      name: "",
      password: "",
      updateUserFunction: setCurrentUser,
    };
    setCurrentUser(newUser);
  }, [setCurrentUser]);

  //alert(currentUser.name);
  return (
    <AuthContext.Provider value={currentUser}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />}>
            <Route index element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/pictures" element={<PicturesPage />} />
            <Route path="/camping2" element={<CampingPage />} />
            {/* <Route path="/camping3" element={<CampingPageAdmin />} /> */}
            <Route
              path="/camping-documents"
              element={<CampingDocumentsPage />}
            />
            <Route path="/unauthorized" element={<NotAuthorizedPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
