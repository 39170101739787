import { Box, Card, Link } from "@mui/material";

import MapImage from "../../images/usaMap.gif";

interface OwnProps {
  trips: number;
  miles: number;
}

type Props = OwnProps;

const AllTripsAllCampers = (props: Props) => {
  return (
    <Card
      sx={{
        maxWidth: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Box marginLeft={1} marginRight={1}>
        <img
          src={MapImage}
          alt="Map of US with visited states highlighted"
          height="auto"
          width="100%"
        ></img>
      </Box>
      <Box textAlign={"center"}>
        <Link
          href="https://www.google.com/maps/d/u/0/edit?mid=17ASj-r2Ql5Op3hL2iFY_NfTSqCyG7NWn&usp=sharing"
          target="_blank"
          rel="noopener"
          sx={{
            ":hover": {
              bgcolor: "primary.main",
              color: "white",
            },
          }}
        >
          Detailed Map
        </Link>
      </Box>
      <Box margin={"10px"} textAlign={"center"}>
        <span>{`TRIPS: ${props.trips} MILES: ${props.miles}`}</span>
      </Box>
    </Card>
  );
};

export default AllTripsAllCampers;
