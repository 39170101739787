import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

interface OwnProps {
  title: string;
  url: string;
  image: string;
  description: string;
}

type Props = OwnProps;

const MainPageOption = (props: Props) => {
  return (
    <Link to={props.url}>
      <Card
        sx={{
          maxWidth: 300,
          marginTop: {
            xs: "2vh",
            sm: "4vh",
            md: "6vh",
            lg: "8vh",
            xl: "15vh",
          },
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "10vh",
        }}
      >
        <CardActionArea>
          <CardMedia component="img" image={props.image} alt={props.title} />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              textAlign={"center"}
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default MainPageOption;
