import { Box, Card } from "@mui/material";

import PodImage from "../../images/rpodSmall.jpg";
import CampliteImage from "../../images/campLiteSmall.jpg";
import ReflectionImage from "../../images/reflectionSmall.jpg";

interface OwnProps {
  trips: number;
  miles: number;
  camper: string;
}

type Props = OwnProps;

const AllTripsSpecificCamper = (props: Props) => {
  let image: string = ReflectionImage;

  switch (props.camper) {
    case "CampLite":
      image = CampliteImage;
      break;
    case "R-Pod":
      image = PodImage;
      break;
    case "Reflection":
      image = ReflectionImage;
      break;
    default:
      console.log("No such day exists!");
      break;
  }

  return (
    <Card
      sx={{
        maxWidth: "fit-content",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <Box margin={"10px"}>
        <img src={image} alt="Map of US with visited states highlighted"></img>
      </Box>
      <Box margin={"10px"} textAlign={"center"}>
        <span>{`TRIPS: ${props.trips} MILES: ${props.miles}`}</span>
      </Box>
    </Card>
  );
};

export default AllTripsSpecificCamper;
