import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

interface OwnProps {
  names: string[];
  valueChangeFunction: any;
  fieldName: string;
}

type Props = OwnProps;

const TripFilterSelect = (props: Props) => {
  const [personName, setPersonName] = useState<string>("");

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    if (event && event.target) {
      setPersonName(event.target.value);
      props.valueChangeFunction(event);
    }
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel id="demo-multiple-name-label">{props.fieldName}</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        //multiple
        value={personName}
        onChange={handleChange}
        input={<OutlinedInput label={props.fieldName} />}
      >
        <MenuItem key={"All"} value={""}>
          {"======"}
        </MenuItem>

        {props.names.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TripFilterSelect;
