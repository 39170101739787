import { useEffect, useState } from "react";
import { CampingDocumentsDirectorySummaryInterface } from "./CampingDocumentsDirectorySummaryInterface";
import { Box, Card, Link, List, ListItem, Typography } from "@mui/material";

const CampingDocuments = () => {
  const baseURL: string = process.env.REACT_APP_BASE_API_URL
    ? process.env.REACT_APP_BASE_API_URL
    : "";
  const getCampingDocumentsURL: string = process.env
    .REACT_APP_CAMPING_DOCUMENTS_DIRECTORY_CONTENTS_API_URL
    ? process.env.REACT_APP_CAMPING_DOCUMENTS_DIRECTORY_CONTENTS_API_URL
    : "";
  const documentsURL = `${baseURL}${getCampingDocumentsURL}`;
  const [getResult, setGetResult] = useState<
    CampingDocumentsDirectorySummaryInterface[]
  >([]);

  useEffect(() => {
    getAllData();
  }, [documentsURL]);

  const getAllData = async () => {
    try {
      const res = await fetch(`${documentsURL}`);
      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }

      const data: CampingDocumentsDirectorySummaryInterface[] =
        await res.json();

      setGetResult(data);
    } catch (err: any) {
      setGetResult(err.message);
    }
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", alignContent: "center" }}>
      <Typography
        textAlign={"center"}
        width={"100%"}
        marginBottom={"20px"}
        sx={{
          fontSize: "2rem",
          fontFamily: "roboto",
          fontWeight: 700,
          letterSpacing: ".3rem",
        }}
      >
        Camping Documents
      </Typography>
      <Card sx={{ width: "100%", padding: "20px" }}>
        <List>
          {getResult &&
            getResult.map(
              (
                row: CampingDocumentsDirectorySummaryInterface,
                index: number
              ) => (
                <ListItem disablePadding>
                  <Link
                    fontSize={"large"}
                    key={index}
                    target="_blank"
                    rel="noopener"
                    href={`${row.path}${row.name}`}
                  >
                    {row.name}
                  </Link>
                </ListItem>
              )
            )}
        </List>
      </Card>
    </Box>
  );
};

export default CampingDocuments;
