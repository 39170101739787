import { Divider, Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { PhotoDirectorySummaryInterface } from "./PhotoDirectorySummaryInterface";

interface PathParts {
  name: string;
  displayName: string;
  url: string;
}

interface OwnProps {
  url: string;
}

type Props = OwnProps;

const PhotoDirectoryBreadCrumb = (props: Props) => {
  const prepPath = (): PathParts[] => {
    const pathParts: PathParts[] = [];
    let previousPath: string = "/";

    props.url
      .replace("//", "/")
      .split("/")
      .map((row: string, index: number) => {
        pathParts.push({
          displayName: index === 0 ? "Pictures" : row.split(" - ").at(-1) || "",
          name: row,
          url: `${previousPath}`,
        });
        previousPath = previousPath.concat(`${row}/`);
      });

    pathParts.pop();
    return pathParts;
  };

  return (
    <Stack
      direction="row"
      divider={
        <Divider component="div" role="presentation">
          <ArrowForwardIcon />
        </Divider>
      }
    >
      {prepPath().map((row: PathParts, index: number) => {
        const stateData: PhotoDirectorySummaryInterface = {
          name: row.name,
          path: row.url,
          type: "directory",
        };
        return (
          <Link key={index} to={"/pictures"} state={stateData}>
            {row.displayName}
          </Link>
        );
      })}
    </Stack>
  );
};

export default PhotoDirectoryBreadCrumb;
